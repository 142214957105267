import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { getMarkerIcon } from "./customMarkers";
import mapStyles from "./mapStyles";
import { clusterStyles, clusterStylesBySize } from "./customClusterStyles"; // Import custom cluster styles

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 48.8566, // Default to Paris coordinates
  lng: 2.3522,
};

function PlaygroundMap({ onMarkerClick }) {
  const [playgrounds, setPlaygrounds] = useState([]);
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    if (isLoaded && map) {
      fetchPlaygrounds();
    }
  }, [isLoaded, map]);

  const fetchPlaygrounds = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/playgrounds`
      );
      const data = await response.json();
      const approvedPlaygrounds = data.filter(
        (playground) => playground.status === "approved"
      );
      setPlaygrounds(approvedPlaygrounds);
      createMarkers(approvedPlaygrounds);
      fitMapToBounds(approvedPlaygrounds);
    } catch (error) {
      console.error("Failed to fetch playgrounds:", error);
    }
  };

  const handleMarkerClick = (playground, index) => {
    onMarkerClick(playground, index); // Now passes both playground and index
  };

  const createMarkers = (playgrounds) => {
    console.log('Creating markers for playgrounds:', playgrounds);
    
    const markers = playgrounds.map((playground, index) => {
      const position = new window.google.maps.LatLng(
        playground.latitude,
        playground.longitude
      );

      const isOpen = computeOpenStatus(playground);
      const icon = getMarkerIcon(isOpen);
      
      console.log('Creating marker:', {
        position,
        isOpen,
        icon
      });

      const marker = new window.google.maps.Marker({
        position,
        title: playground.name,
        icon,
        map,
      });

      marker.addListener("click", () => handleMarkerClick(playground, index));
      return marker;
    });

    console.log('Created markers:', markers);

    // Create marker cluster
    const markerCluster = new MarkerClusterer({
      markers,
      map,
      styles: clusterStylesBySize,
      maxZoom: 15,
      gridSize: 60,
      renderer: {
        render: ({ count, position }) => {
          const clusterIndex = count < 10 ? 0 : count < 100 ? 1 : 2;
          const style = clusterStylesBySize[clusterIndex];
          
          return new window.google.maps.Marker({
            position,
            icon: {
              ...style,
              anchor: new window.google.maps.Point(style.width/2, style.height/2),
            },
            label: {
              text: String(count),
              color: style.textColor,
              fontSize: `${style.textSize}px`,
              fontFamily: style.fontFamily,
              fontWeight: "bold",
              className: 'cluster-label'
            },
            labelOrigin: new window.google.maps.Point(style.width/2, style.height/2),
            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
          });
        },
      },
    });

    markerCluster.addMarkers(markers);
  };

  const fitMapToBounds = (playgrounds) => {
    if (playgrounds.length === 0) return;

    const bounds = new window.google.maps.LatLngBounds();
    playgrounds.forEach((playground) => {
      bounds.extend(
        new window.google.maps.LatLng(playground.latitude, playground.longitude)
      );
    });
    map.fitBounds(bounds);
  };

  const computeOpenStatus = (playground) => {
    if (playground.isOpen247) {
      return true; // Always open if it's marked as open 24/7
    }

    const now = new Date();
    const dayNames = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const todayName = dayNames[now.getDay()];
    const currentTime = now.getHours() + now.getMinutes() / 60;

    let todaysSchedule = playground.schedule.find((period) => {
      const startIdx = dayNames.indexOf(period.startDay);
      const endIdx = dayNames.indexOf(
        period.endDay.length > 0 ? period.endDay : period.startDay
      );
      return startIdx <= endIdx
        ? now.getDay() >= startIdx && now.getDay() <= endIdx
        : now.getDay() >= startIdx || now.getDay() <= endIdx;
    });

    if (
      !todaysSchedule ||
      !todaysSchedule.breaks ||
      todaysSchedule.breaks.length === 0
    ) {
      return false; // Closed today
    }

    // Check if current time is within any break periods
    return todaysSchedule.breaks.some((breakTime) => {
      const breakStart = parseFloat(
        breakTime.start
          .split(":")
          .reduce(
            (acc, time, idx) => acc + parseInt(time, 10) / (idx ? 60 : 1),
            0
          )
      );
      const breakEnd = parseFloat(
        breakTime.end
          .split(":")
          .reduce(
            (acc, time, idx) => acc + parseInt(time, 10) / (idx ? 60 : 1),
            0
          )
      );
      return currentTime >= breakStart && currentTime < breakEnd;
    });
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={11.5}
      onLoad={(map) => setMap(map)}
      options={{
        styles: mapStyles,
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
    />
  );
}

export default PlaygroundMap;
