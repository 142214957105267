import clusterIcon from "./assets/icons/cluster_icon.svg";

export const clusterStyles = [
  {
    textColor: "white",
    textSize: 14,
    height: 40,
    width: 40,
    url: "/assets/icons/cluster_icon.svg",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
];

// Create circular cluster backgrounds with different colors based on size
const smallCluster = {
  url: clusterIcon,
  height: 35,
  width: 35,
  textColor: "#FFFFFF",
  textSize: 28,
  fontFamily: "Thermo TT ZeroTrial, sans-serif",
  textAlign: "center",
  anchorText: [10, 19], // Moved X coordinate more to the left
};

const mediumCluster = {
  url: clusterIcon,
  height: 40,
  width: 40,
  textColor: "#FFFFFF",
  textSize: 25,
  fontFamily: "Thermo TT ZeroTrial, sans-serif",
  textAlign: "center",
  anchorText: [10, 22], // Moved X coordinate more to the left
};

const largeCluster = {
  url: clusterIcon,
  height: 45,
  width: 45,
  textColor: "#FFFFFF",
  textSize: 16,
  fontFamily: "Thermo TT ZeroTrial, sans-serif",
  textAlign: "center",
  anchorText: [10, 24], // Moved X coordinate more to the left
};

export const clusterStylesBySize = [
  smallCluster, // For clusters < 10
  mediumCluster, // For clusters < 100
  largeCluster, // For clusters >= 100
];
