import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { LoadScript } from "@react-google-maps/api";
import PlaygroundForm from "./PlaygroundForm";
import PlaygroundMap from "./PlaygroundMap";
import About from "./About";
import PlaygroundDetails from "./PlaygroundDetails";
import ModerationPage from "./ModerationPage"; // Import the ModerationPage component
import logo from "./assets/icons/logo.svg";

// Define libraries array outside of component
const libraries = ["places"];

function App() {
  const [activeSection, setActiveSection] = useState(null);
  const [selectedPlayground, setSelectedPlayground] = useState(null);
  const [containerHeight, setContainerHeight] = useState(0);
  
  const containerRef = useRef(null);

  // Handle opening and closing of the container
  useEffect(() => {
    if (!containerRef.current) return;

    if (activeSection) {
      // Wait for next frame to ensure content is rendered
      requestAnimationFrame(() => {
        // And then wait a tiny bit more to ensure all styles are applied
        setTimeout(() => {
          const container = containerRef.current;
          if (!container) return;

          const totalHeight = Array.from(container.children).reduce((height, child) => {
            const styles = window.getComputedStyle(child);
            
            // Get all box model measurements
            const marginTop = parseInt(styles.marginTop) || 0;
            const marginBottom = parseInt(styles.marginBottom) || 0;
            const paddingTop = parseInt(styles.paddingTop) || 0;
            const paddingBottom = parseInt(styles.paddingBottom) || 0;
            const borderTop = parseInt(styles.borderTopWidth) || 0;
            const borderBottom = parseInt(styles.borderBottomWidth) || 0;
            
            const elementContribution = {
              element: child,
              content: child.scrollHeight,  // Use scrollHeight instead of offsetHeight - paddingTop...
              padding: paddingTop + paddingBottom,
              margins: marginTop + marginBottom,
              borders: borderTop + borderBottom,
              totalHeight: child.scrollHeight + marginTop + marginBottom
            };
            
            console.log('Element measurements:', elementContribution);
            
            return height + elementContribution.totalHeight;
          }, 0);

          // Add container's own padding if any
          const containerStyles = window.getComputedStyle(container);
          const containerPaddingTop = parseInt(containerStyles.paddingTop) || 0;
          const containerPaddingBottom = parseInt(containerStyles.paddingBottom) || 0;
          
          const finalHeight = totalHeight + containerPaddingTop + containerPaddingBottom;
          
          console.log('Final container height:', {
            childrenHeight: totalHeight,
            containerPadding: containerPaddingTop + containerPaddingBottom,
            finalHeight
          });

          setContainerHeight(finalHeight);
        }, 50); // Small delay to ensure everything is rendered
      });
    } else {
      // Closing: set height to 0
      setContainerHeight(0);
    }
  }, [activeSection]);

  const handlePlusClick = () => {
    if (activeSection) {
      setActiveSection(null);
    } else {
      setActiveSection("form");
      setSelectedPlayground(null);
    }
  };

  const handleAboutClick = () => {
    if (activeSection === "about") {
      setActiveSection(null);
    } else {
      setActiveSection("about");
    }
  };

  const handleMarkerClick = (playground, index) => {
    console.log("Marker clicked:", playground, "Index:", index);
    setSelectedPlayground({ data: playground, index: index });
    setActiveSection("details");
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDa6fpfYDkoDMqtXen14ZLNrUYkIRs_k04"
      libraries={libraries}
    >
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                <div className="hub">
                  <header className="header">
                    <div
                      className={`header__plus ${activeSection ? "header__plus--cross" : ""}`}
                      onClick={handlePlusClick}
                    ></div>
                    <img src={logo} className="header__logo" alt="logo" />
                    <div className="header__about" onClick={handleAboutClick}>
                      i
                    </div>
                  </header>
                  <div 
                    ref={containerRef}
                    className={`hub__container ${activeSection ? 'hub__container--open' : ''}`}
                    style={{ height: `${containerHeight}px` }}
                  >
                    {activeSection === "form" && <PlaygroundForm />}
                    {activeSection === "about" && <About />}
                    {activeSection === "details" && selectedPlayground && (
                      <PlaygroundDetails
                        playground={selectedPlayground.data}
                        index={selectedPlayground.index}
                      />
                    )}
                  </div>
                </div>
                <div className="map">
                  <PlaygroundMap onMarkerClick={handleMarkerClick} />
                </div>
              </div>
            }
          />
          <Route path="/moderation" element={<ModerationPage />} />
        </Routes>
      </Router>
    </LoadScript>
  );
}

export default App;
