export const getMarkerIcon = (isOpen) => {
  const iconSVG = isOpen
    ? `<svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg" style="background-color: transparent;">
<defs>
<linearGradient id="paint0_angular_509_23" gradientUnits="userSpaceOnUse">
<stop offset="0" stop-color="#00FF38"/>
<stop offset="0.25" stop-color="#00FF38" stop-opacity="0"/>
<stop offset="0.5" stop-color="#00FF38"/>
<stop offset="0.75" stop-color="#00FF38" stop-opacity="0"/>
<stop offset="1" stop-color="#00FF38"/>
</linearGradient>
<style>
@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.rotating-circle {
  animation: rotate 4s linear infinite;
  transform-origin: center;
}
</style>
</defs>
<path class="rotating-circle" fill-rule="evenodd" clip-rule="evenodd" d="M29.2106 20.7978C27.113 27.6392 19.8665 31.4848 13.0251 29.3872C6.18377 27.2896 2.33818 20.0431 4.43578 13.2017C6.53338 6.36037 13.7799 2.51477 20.6212 4.61238C27.4626 6.70998 31.3082 13.9565 29.2106 20.7978ZM13.3183 28.4311C19.6316 30.3668 26.3188 26.8181 28.2545 20.5047C30.1902 14.1913 26.6415 7.50416 20.3281 5.56845C14.0147 3.63274 7.32756 7.18153 5.39185 13.4949C3.45614 19.8082 7.00493 26.4954 13.3183 28.4311Z" fill="none" stroke="url(#paint0_angular_509_23)" stroke-width="1.5"/>
<path d="M27.0418 17.0001C27.0418 22.6437 22.4668 27.2187 16.8232 27.2187C11.1797 27.2187 6.60464 22.6437 6.60464 17.0001C6.60464 11.3565 11.1797 6.78149 16.8232 6.78149C22.4668 6.78149 27.0418 11.3565 27.0418 17.0001Z" fill="black"/>
<path d="M17.8568 19.5569C17.3742 19.2949 16.5881 19.2949 16.1054 19.5569C14.0368 20.7015 12.0923 21.0049 11.0305 20.4395C11.0167 20.4257 11.0305 20.4946 11.058 20.536C11.7751 21.5841 12.906 22.2461 14.1885 22.5219C14.2161 22.5357 14.2299 22.5081 14.2023 22.4805C13.9817 22.2599 13.7748 22.0392 13.5541 21.8048C13.4714 21.7082 13.4576 21.6117 13.5404 21.5841C14.5057 21.3635 15.5814 20.9222 16.6846 20.2326C16.8501 20.1361 17.1121 20.1361 17.2638 20.2326C18.3533 20.9222 19.429 21.3635 20.3805 21.5841C20.4633 21.5979 20.4495 21.7082 20.3667 21.8048C20.1599 22.0392 19.9392 22.2736 19.7186 22.4805C19.691 22.5081 19.7048 22.5357 19.7324 22.5219C21.0287 22.2461 22.1457 21.5979 22.8766 20.5498C22.9042 20.5222 22.918 20.4533 22.9042 20.4533C21.8561 20.9911 19.9117 20.7015 17.8568 19.5569Z" fill="#FFFFFF"/>
<path d="M15.7882 22.8389C15.7882 22.9217 15.6779 22.9631 15.5538 22.9355C15.2504 22.8527 14.9608 22.7562 14.6712 22.6459C14.6298 22.6321 14.616 22.6459 14.6298 22.6735C15.3056 23.7353 16.0227 24.7007 16.7536 25.5695C16.7812 25.5971 16.8363 25.5833 16.8363 25.5557C16.8363 23.9008 16.8363 22.2459 16.8363 20.5773C16.4778 20.8117 16.133 21.0186 15.7745 21.1978C15.7882 21.7495 15.7882 22.2873 15.7882 22.8389Z" fill="#FFFFFF"/>
<path d="M19.3463 22.6045C19.0567 22.7287 18.7671 22.8252 18.4775 22.9079C18.3533 22.9355 18.2568 22.8942 18.2568 22.8114C18.2568 22.2598 18.2568 21.7219 18.2568 21.1703C17.912 20.991 17.5535 20.7842 17.1949 20.5635C17.1949 22.2184 17.1949 23.8733 17.1949 25.542C17.1949 25.5695 17.2501 25.5833 17.2777 25.5558C17.9948 24.6869 18.7119 23.7216 19.3876 22.6459C19.4014 22.6183 19.3876 22.5908 19.3463 22.6045Z" fill="#FFFFFF"/>
<path d="M15.8158 18.1501C13.7748 16.4538 11.8717 13.9025 10.9063 10.51C10.8926 10.4549 10.865 10.4273 10.865 10.4687C11.0443 11.7788 11.2511 13.0475 11.4856 14.3025C11.4856 14.3301 11.5131 14.3852 11.5407 14.3852C11.6648 14.3852 11.7889 14.3714 11.9131 14.3576C11.9682 14.3576 12.0648 14.4542 12.1337 14.5645C12.9198 16.0539 13.8851 17.3226 14.9332 18.3432C15.0849 18.4948 15.0849 18.6741 14.9332 18.7293C13.8851 19.1568 12.906 19.3499 12.1199 19.3085C12.051 19.3085 11.9544 19.1982 11.8993 19.0741C11.7752 18.7707 11.651 18.4673 11.5269 18.1501C11.5131 18.1087 11.4856 18.0811 11.4718 18.0949C11.2373 18.7155 11.0305 19.2947 10.865 19.8601C10.865 19.8739 10.8788 19.9567 10.9063 19.9567C11.8717 20.591 13.7748 20.3842 15.8296 19.3085C16.2985 19.0603 16.2985 18.5362 15.8158 18.1501Z" fill="#FFFFFF"/>
<path d="M11.5131 17.8054C11.4856 17.571 11.4718 17.3503 11.458 17.1159C11.4442 17.0194 11.5131 17.0332 11.5821 17.1297C12.1061 17.7917 12.7405 18.3295 13.4576 18.7294C13.761 18.6605 14.092 18.5777 14.423 18.4674C12.1061 17.6124 10.2582 15.53 10.2444 12.2202C10.2444 12.1651 10.2444 12.1788 10.2444 12.2478C10.2444 14.3991 10.7271 16.2471 11.4856 17.7779C11.4856 17.8192 11.5131 17.833 11.5131 17.8054Z" fill="#FFFFFF"/>
<path d="M10.2306 12.0272C10.2582 15.3231 12.1199 17.3917 14.4368 18.2468C14.1058 17.8882 13.7886 17.5021 13.4714 17.1021C12.7543 16.7022 12.1199 16.1782 11.5959 15.5162C11.5132 15.4197 11.458 15.268 11.4718 15.1852C11.4856 15.0059 11.5132 14.8405 11.5407 14.6612C11.5407 14.6336 11.5132 14.5922 11.4994 14.5784C10.7271 14.013 10.2444 13.1304 10.2444 11.9444C10.2306 11.903 10.2306 11.9582 10.2306 12.0272Z" fill="#FFFFFF"/>
<path d="M22.4354 18.1502C22.3113 18.4674 22.1872 18.7708 22.0631 19.0741C22.0079 19.1983 21.9114 19.3086 21.8424 19.3086C21.0563 19.35 20.091 19.1569 19.0429 18.7294C18.8912 18.6604 18.8912 18.4949 19.0429 18.3432C20.091 17.3089 21.0701 16.0402 21.8562 14.537C21.9252 14.4129 22.0217 14.3164 22.0769 14.3164C22.201 14.3301 22.3251 14.3439 22.4492 14.3439C22.463 14.3439 22.4906 14.3026 22.5044 14.2612C22.7388 13.0062 22.9457 11.7375 23.1112 10.4274C23.1112 10.386 23.0974 10.4136 23.0698 10.4688C22.1044 13.8751 20.2013 16.4401 18.1465 18.1364C17.6638 18.5225 17.6638 19.0466 18.1465 19.3086C20.1875 20.3705 22.0907 20.5911 23.056 19.9567C23.0698 19.943 23.0974 19.874 23.0974 19.8602C22.9181 19.2948 22.7112 18.7156 22.4768 18.095C22.4768 18.0812 22.4492 18.1226 22.4354 18.1502Z" fill="#FFFFFF"/>
<path d="M23.7455 11.9443C23.7455 13.1441 23.2628 14.0267 22.5044 14.5922C22.4906 14.6059 22.463 14.6611 22.463 14.6749C22.4906 14.8542 22.5044 15.0197 22.5181 15.1989C22.5319 15.2679 22.463 15.4196 22.394 15.5299C21.87 16.1919 21.2356 16.7297 20.5185 17.1296C20.2151 17.5434 19.8841 17.9295 19.5531 18.2881C21.87 17.433 23.7179 15.3506 23.7317 12.0409C23.7455 11.9719 23.7455 11.903 23.7455 11.9443Z" fill="#FFFFFF"/>
<path d="M23.7455 12.3029C23.7179 15.5989 21.8562 17.6675 19.5393 18.5225C19.8703 18.6328 20.1875 18.7294 20.5047 18.7845C21.2218 18.3846 21.8562 17.8606 22.3802 17.1986C22.4629 17.1021 22.5181 17.0883 22.5043 17.1848C22.4905 17.4193 22.4629 17.6399 22.4354 17.8744C22.4354 17.9019 22.4629 17.8881 22.4767 17.8468C23.249 16.316 23.7317 14.4818 23.7317 12.3305C23.7455 12.2478 23.7455 12.234 23.7455 12.3029Z" fill="#FFFFFF"/>
<path d="M16.1054 17.9985C16.5881 18.3847 17.3742 18.3847 17.8569 17.9985C19.9255 16.3712 21.87 13.8889 22.9318 10.5653C22.9456 10.5102 22.9318 10.5102 22.9043 10.5516C22.1871 12.1099 21.0563 13.2821 19.7738 14.0544C19.7462 14.0682 19.7324 14.1095 19.76 14.1095C19.9806 14.1509 20.1875 14.1785 20.4081 14.1923C20.4909 14.1923 20.5047 14.2888 20.4219 14.3854C19.4566 15.6403 18.3809 16.647 17.2777 17.4193C17.1122 17.5296 16.8501 17.5296 16.6984 17.4193C15.609 16.647 14.5333 15.6541 13.5818 14.4129C13.499 14.3164 13.5128 14.2199 13.5955 14.2199C13.8024 14.2061 14.0231 14.1785 14.2437 14.1371C14.2713 14.1371 14.2575 14.0958 14.2299 14.082C12.9336 13.3097 11.8165 12.1375 11.0856 10.5929C11.0581 10.5378 11.0443 10.5516 11.0581 10.6067C12.1061 13.9165 14.0506 16.385 16.1054 17.9985Z" fill="#FFFFFF"/>
<path d="M18.1878 14.6198C18.1878 14.5371 18.2982 14.4543 18.4223 14.4405C18.7257 14.3992 19.0153 14.3578 19.3049 14.275C19.3463 14.2612 19.3601 14.2337 19.3463 14.2199C18.6705 13.6131 17.9534 12.896 17.2225 12.0685C17.1949 12.0409 17.1398 12.0547 17.1398 12.0823C17.1398 13.7372 17.1398 15.3921 17.1398 17.0608C17.4983 16.8125 17.8431 16.5505 18.2016 16.2609C18.1878 15.7231 18.1878 15.1714 18.1878 14.6198Z" fill="#FFFFFF"/>
<path d="M14.616 14.275C14.9056 14.3439 15.1952 14.3991 15.4848 14.4405C15.6089 14.4542 15.7055 14.537 15.7055 14.6197C15.7055 15.1714 15.7055 15.7092 15.7055 16.2608C16.0503 16.5504 16.4088 16.8262 16.7674 17.0745C16.7674 15.4196 16.7674 13.7647 16.7674 12.096C16.7674 12.0685 16.7122 12.0547 16.6846 12.0822C15.9675 12.8959 15.2504 13.5992 14.5746 14.206C14.5609 14.2336 14.5884 14.275 14.616 14.275Z" fill="#FFFFFF"/>
</svg>`
    : `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style="background-color: transparent;">
<path d="M21.0418 11.0001C21.0418 16.6437 16.4668 21.2187 10.8232 21.2187C5.17966 21.2187 0.604645 16.6437 0.604645 11.0001C0.604645 5.35651 5.17966 0.781494 10.8232 0.781494C16.4668 0.781494 21.0418 5.35651 21.0418 11.0001Z" fill="black"/>
<path d="M11.8568 13.5569C11.3742 13.2949 10.5881 13.2949 10.1054 13.5569C8.03682 14.7015 6.09233 15.0049 5.03045 14.4395C5.01666 14.4257 5.03045 14.4946 5.05803 14.536C5.77515 15.5841 6.90599 16.2461 8.18852 16.5219C8.2161 16.5357 8.22989 16.5081 8.20231 16.4805C7.98166 16.2599 7.7748 16.0392 7.55415 15.8048C7.4714 15.7082 7.45761 15.6117 7.54036 15.5841C8.5057 15.3635 9.58138 14.9222 10.6846 14.2326C10.8501 14.1361 11.1121 14.1361 11.2638 14.2326C12.3533 14.9222 13.429 15.3635 14.3805 15.5841C14.4633 15.5979 14.4495 15.7082 14.3667 15.8048C14.1599 16.0392 13.9392 16.2736 13.7186 16.4805C13.691 16.5081 13.7048 16.5357 13.7324 16.5219C15.0287 16.2461 16.1457 15.5979 16.8766 14.5498C16.9042 14.5222 16.918 14.4533 16.9042 14.4533C15.8561 14.9911 13.9117 14.7015 11.8568 13.5569Z" fill="#FFFFFF"/>
<path d="M9.78825 16.8389C9.78825 16.9217 9.67792 16.9631 9.55381 16.9355C9.25041 16.8527 8.96081 16.7562 8.6712 16.6459C8.62983 16.6321 8.61604 16.6459 8.62983 16.6735C9.30557 17.7353 10.0227 18.7007 10.7536 19.5695C10.7812 19.5971 10.8363 19.5833 10.8363 19.5557C10.8363 17.9008 10.8363 16.2459 10.8363 14.5773C10.4778 14.8117 10.133 15.0186 9.77446 15.1978C9.78825 15.7495 9.78825 16.2873 9.78825 16.8389Z" fill="#FFFFFF"/>
<path d="M13.3463 16.6045C13.0567 16.7287 12.7671 16.8252 12.4775 16.9079C12.3533 16.9355 12.2568 16.8942 12.2568 16.8114C12.2568 16.2598 12.2568 15.7219 12.2568 15.1703C11.912 14.991 11.5535 14.7842 11.1949 14.5635C11.1949 16.2184 11.1949 17.8733 11.1949 19.542C11.1949 19.5695 11.2501 19.5833 11.2777 19.5558C11.9948 18.6869 12.7119 17.7216 13.3876 16.6459C13.4014 16.6183 13.3876 16.5908 13.3463 16.6045Z" fill="#FFFFFF"/>
<path d="M9.81583 12.1501C7.77481 10.4538 5.87169 7.90255 4.90635 4.51004C4.89256 4.45488 4.86497 4.4273 4.86497 4.46867C5.04425 5.77878 5.25111 7.04753 5.48555 8.30248C5.48555 8.33006 5.51314 8.38522 5.54072 8.38522C5.66483 8.38522 5.78895 8.37143 5.91307 8.35764C5.96823 8.35764 6.06476 8.45418 6.13372 8.5645C6.91979 10.0539 7.88513 11.3226 8.93323 12.3432C9.08492 12.4948 9.08492 12.6741 8.93323 12.7293C7.88513 13.1568 6.906 13.3499 6.11993 13.3085C6.05097 13.3085 5.95444 13.1982 5.89928 13.0741C5.77516 12.7707 5.65104 12.4673 5.52693 12.1501C5.51314 12.1087 5.48555 12.0811 5.47176 12.0949C5.23732 12.7155 5.03046 13.2947 4.86497 13.8601C4.86497 13.8739 4.87876 13.9567 4.90635 13.9567C5.87169 14.591 7.77481 14.3842 9.82962 13.3085C10.2985 13.0603 10.2985 12.5362 9.81583 12.1501Z" fill="#FFFFFF"/>
<path d="M5.51314 11.8054C5.48556 11.571 5.47177 11.3503 5.45798 11.1159C5.44419 11.0194 5.51314 11.0332 5.5821 11.1297C6.10614 11.7917 6.74051 12.3295 7.45763 12.7294C7.76102 12.6605 8.092 12.5777 8.42298 12.4674C6.10614 11.6124 4.25819 9.52998 4.2444 6.22021C4.2444 6.16505 4.2444 6.17884 4.2444 6.2478C4.2444 8.39914 4.72707 10.2471 5.48556 11.7779C5.48556 11.8192 5.51314 11.833 5.51314 11.8054Z" fill="#FFFFFF"/>
<path d="M4.23062 6.02716C4.2582 9.32313 6.11994 11.3917 8.43678 12.2468C8.1058 11.8882 7.78861 11.5021 7.47143 11.1021C6.75431 10.7022 6.11994 10.1782 5.59589 9.5162C5.51315 9.41966 5.45799 9.26797 5.47178 9.18522C5.48557 9.00594 5.51315 8.84046 5.54073 8.66118C5.54073 8.63359 5.51315 8.59222 5.49936 8.57843C4.72708 8.01301 4.24441 7.13041 4.24441 5.94441C4.23062 5.90304 4.23062 5.9582 4.23062 6.02716Z" fill="#FFFFFF"/>
<path d="M16.4354 12.1502C16.3113 12.4674 16.1872 12.7708 16.0631 13.0741C16.0079 13.1983 15.9114 13.3086 15.8424 13.3086C15.0563 13.35 14.091 13.1569 13.0429 12.7294C12.8912 12.6604 12.8912 12.4949 13.0429 12.3432C14.091 11.3089 15.0701 10.0402 15.8562 8.53701C15.9252 8.41289 16.0217 8.31636 16.0769 8.31636C16.201 8.33015 16.3251 8.34394 16.4492 8.34394C16.463 8.34394 16.4906 8.30257 16.5044 8.26119C16.7388 7.00624 16.9457 5.7375 17.1112 4.42738C17.1112 4.38601 17.0974 4.41359 17.0698 4.46876C16.1044 7.87506 14.2013 10.4401 12.1465 12.1364C11.6638 12.5225 11.6638 13.0466 12.1465 13.3086C14.1875 14.3705 16.0907 14.5911 17.056 13.9567C17.0698 13.943 17.0974 13.874 17.0974 13.8602C16.9181 13.2948 16.7112 12.7156 16.4768 12.095C16.4768 12.0812 16.4492 12.1226 16.4354 12.1502Z" fill="#FFFFFF"/>
<path d="M17.7455 5.94434C17.7455 7.14413 17.2628 8.02673 16.5044 8.59215C16.4906 8.60594 16.463 8.66111 16.463 8.6749C16.4906 8.85418 16.5044 9.01966 16.5181 9.19894C16.5319 9.2679 16.463 9.41959 16.394 9.52992C15.87 10.1919 15.2356 10.7297 14.5185 11.1296C14.2151 11.5434 13.8841 11.9295 13.5531 12.2881C15.87 11.433 17.7179 9.35064 17.7317 6.04088C17.7455 5.97192 17.7455 5.90297 17.7455 5.94434Z" fill="#FFFFFF"/>
<path d="M17.7455 6.30292C17.7179 9.59889 15.8562 11.6675 13.5393 12.5225C13.8703 12.6328 14.1875 12.7294 14.5047 12.7845C15.2218 12.3846 15.8562 11.8606 16.3802 11.1986C16.4629 11.1021 16.5181 11.0883 16.5043 11.1848C16.4905 11.4193 16.4629 11.6399 16.4354 11.8744C16.4354 11.9019 16.4629 11.8881 16.4767 11.8468C17.249 10.316 17.7317 8.48184 17.7317 6.3305C17.7455 6.24775 17.7455 6.23396 17.7455 6.30292Z" fill="#FFFFFF"/>
<path d="M10.1054 11.9985C10.5881 12.3847 11.3742 12.3847 11.8569 11.9985C13.9255 10.3712 15.87 7.8889 16.9318 4.56534C16.9456 4.51018 16.9318 4.51018 16.9043 4.55155C16.1871 6.1099 15.0563 7.28211 13.7738 8.05439C13.7462 8.06818 13.7324 8.10955 13.76 8.10955C13.9806 8.15092 14.1875 8.1785 14.4081 8.19229C14.4909 8.19229 14.5047 8.28883 14.4219 8.38536C13.4566 9.64032 12.3809 10.647 11.2777 11.4193C11.1122 11.5296 10.8501 11.5296 10.6984 11.4193C9.60898 10.647 8.53331 9.65411 7.58175 8.41295C7.49901 8.31641 7.5128 8.21988 7.59554 8.21988C7.8024 8.20609 8.02305 8.1785 8.2437 8.13713C8.27129 8.13713 8.25749 8.09576 8.22991 8.08197C6.93359 7.30969 5.81654 6.13748 5.08564 4.59292C5.05806 4.53776 5.04426 4.55155 5.05806 4.60672C6.10615 7.91648 8.05063 10.385 10.1054 11.9985Z" fill="#FFFFFF"/>
<path d="M12.1878 8.6198C12.1878 8.53706 12.2982 8.45431 12.4223 8.44052C12.7257 8.39915 13.0153 8.35778 13.3049 8.27503C13.3463 8.26124 13.3601 8.23366 13.3463 8.21987C12.6705 7.61308 11.9534 6.89597 11.2225 6.06852C11.1949 6.04094 11.1398 6.05473 11.1398 6.08232C11.1398 7.7372 11.1398 9.39208 11.1398 11.0608C11.4983 10.8125 11.8431 10.5505 12.2016 10.2609C12.1878 9.72306 12.1878 9.17143 12.1878 8.6198Z" fill="#FFFFFF"/>
<path d="M8.61602 8.27497C8.90562 8.34392 9.19523 8.39908 9.48483 8.44045C9.60895 8.45424 9.70548 8.53699 9.70548 8.61973C9.70548 9.17136 9.70548 9.7092 9.70548 10.2608C10.0503 10.5504 10.4088 10.8262 10.7674 11.0745C10.7674 9.41959 10.7674 7.76471 10.7674 6.09604C10.7674 6.06846 10.7122 6.05466 10.6846 6.08225C9.96751 6.8959 9.25039 7.59922 8.57465 8.20601C8.56086 8.23359 8.58844 8.27497 8.61602 8.27497Z" fill="#FFFFFF"/>
</svg>
`;

  return {
    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(iconSVG),
    scaledSize: isOpen ? new window.google.maps.Size(40, 40) : new window.google.maps.Size(32, 32),
    anchor: isOpen ? new window.google.maps.Point(20, 20) : new window.google.maps.Point(16, 16)
  };
};
